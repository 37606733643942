<template>
  <div class="global-blue room">
    <div :style="{position: 'absolute', rigth:100+'px', width: 100+'%'}">
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          class="hidden-xs-only mt-12"
          xl="2"
          lg="2"
          md="2"
          sm="3"
        >
          <v-card
            class="eu mt-12 px-2 ma-2"
            max-height="110"
            max-width="220"
          >

            <!-- <v-img
              v-for="(l, i) in logos_eu[locale]"
              :key="`logo_${i}`"
              class="logos-eu-xl "
              :src="l.img"
              contain
              max-height="100"
   
              eager
            ></v-img> -->
            <v-row>
              <v-col cols="6">

                <v-img
                  class="logos-eu-xl "
                  :src="logos_eu[locale][0].img"
                  :lazy-src="logos_eu[locale][0].img"
                  contain
                  max-height="100"
                  eager
                ></v-img>
              </v-col>
              <v-col cols="6">

                <v-img
                  class="logos-eu-xl "
                  :src="logos_eu[locale][1].img"
                  :lazy-src="logos_eu[locale][1].img"
                  contain
                  max-height="100"
                  eager
                ></v-img>
              </v-col>

            </v-row>
          </v-card>
        </v-col>
        <v-col class="mt-10 mr-2  hidden-sm-and-up" cols="4">
          <v-card
            class="eu mt-12 px-2"
            max-height="65"
            max-width="130"
          >
            <v-row>
              <v-col cols="6">

                <v-img
                  class="logos-eu-xs"
                  :src="logos_eu[locale][0].img"
                  contain
                  max-height="100"
                  eager
                ></v-img>
              </v-col>
              <v-col cols="6">

                <v-img
                  class="logos-eu-xs"
                  :src="logos_eu[locale][1].img"
                  contain
                  max-height="100"
                  eager
                ></v-img>
              </v-col>

            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- <div
      class="eu hidden-lg-and-down"
      :style="{width: 120 + 'px', margin: 5 + 'px', position: 'absolute', right: rightAmount + 'px', top: 55 + 'px'}"
    >
      <div
        v-for="(l, i) in logos_eu[locale]"
        :key="`logo_${i}`"
        class="logos-eu-xl mt-2"
        :style="{ background: 'white', width: 120 + 'px', padding: 5 + 'px' }"
      >
        <v-img
          :src="l.img"
          :max-width="120"
          contain
          eager
        ></v-img>
      </div>

    </div> -->
      <!-- <div
        class="eu hidden-xl-only hidden-md-and-down"
        :style="{width: 110 + 'px', margin: 5 + 'px', position: 'absolute', right: rightAmount + 'px', top: 55 + 'px'}"
      >
        <div
          v-for="(l, i) in logos_eu[locale]"
          :key="`logo_${i}`"
          class="logos-eu-xl mt-2"
          :style="{ background: 'white', width: 110 + 'px', padding: 5 + 'px' }"
        >
          <v-img
            :src="l.img"
            :max-width="110"
            contain
            eager
          ></v-img>
        </div>

      </div>
      <div
        class="eu hidden-lg-and-up hidden-sm-and-down"
        :style="{width: 90 + 'px', margin: 5 + 'px', position: 'absolute', right: rightAmount + 'px', top: 55 + 'px'}"
      >
        <div
          v-for="(l, i) in logos_eu[locale]"
          :key="`logo_${i}`"
          class="logos-eu-xl mt-2"
          :style="{ background: 'white', width: 90 + 'px', padding: 5 + 'px' }"
        >
          <v-img
            :src="l.img"
            :max-width="90"
            contain
            eager
          ></v-img>
        </div>

      </div>
      <div
        class="eu hidden-md-and-up hidden-xs-only"
        :style="{width: 90 + 'px', margin: 5 + 'px', position: 'absolute', right: rightAmount + 'px', top: 55 + 'px'}"
      >
        <div
          v-for="(l, i) in logos_eu[locale]"
          :key="`logo_${i}`"
          class="logos-eu-xl mt-2"
          :style="{ background: 'white', width: 90 + 'px', padding: 5 + 'px' }"
        >
          <v-img
            eager
            :src="l.img"
            :max-width="90"
            contain
          ></v-img>
        </div>

      </div>
      <div
        class="eu hidden-sm-and-up"
        :style="{width: 70 + 'px', margin: 5 + 'px', position: 'absolute', right: rightAmount + 'px', top: 55 + 'px'}"
      >
        <div
          v-for="(l, i) in logos_eu[locale]"
          :key="`logo_${i}`"
          class="logos-eu-xl mt-2"
          :style="{ background: 'white', width: 70 + 'px', padding: 5 + 'px' }"
        >
          <v-img
            eager
            :src="l.img"
            :max-width="70"
            contain
          ></v-img>
        </div>

      </div> -->
    </div>

  </div>
</template>

<style scoped>
.eu {
  z-index: 1;
}
.room {
  height: 60px;
}
</style>

<script>
export default {
  name: "EU_logos",
  data() {
    return {
      maxWidth: this.w != null ? this.w : 120,
      rightAmount: this.r != null ? this.r : 10,
      topAmount: this.t != null ? this.t : "0",
      logos_eu: {
        fi: [
          {
            img: require("@/assets/VipuvoimaaEU_2014_2020_rgb.png"),
            url: null
          },
          {
            img: require("@/assets/EU_EAKR_FI_vertical_20mm_rgb.png"),
            url: null
          }
        ],
        en: [
          {
            img: require("@/assets/Logo_LeverageEU_2014_2020.png"),
            url: null
          },
          {
            img: require("@/assets/Logo_EU_EAKR_EN.png"),
            url: null
          }
        ]
      }
    };
  },
  props: {
    /*     w: {
      type: Number,
      required: true
    },
    r: {
      type: Number,
      required: false
    },
    t: {
      type: String,
      required: false
    } */
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    }
  }
};
</script>